import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import LandingHeader from "../components/LandingHeader"
import Projects from "../components/Projects"
import Services from "../components/Services"
import CalltoAction from "../components/CalltoAction"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <LandingHeader />
    <Projects data={data} />
    <Services />
    <CalltoAction />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        frontmatter {
          title
          description
          image
          date(formatString: "MMMM YYYY")       
          slug
          link
        }
      }
    }
  }
`

