import React from "react";

function Services() {
  return (
    <section className="py-20 bg-blueGray-50">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12">
          <div className="w-full mb-4 lg:mb-0 text-center mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold font-heading">
              <span>My Services</span>
            </h2>
            <p className="text-blueGray-400 leading-loose text-lg">
              I have a 3-step approach to guide you in the process of building or rebuilding your website.
            </p>
          </div>
        </div>
        <div className="bg-gray-200">
          <h3 className="text-2xl font-bold text-center py-8 uppercase">The Process</h3>
          <div className="flex flex-wrap mx-3 mb-6 text-center">
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
              <div className="p-12 bg-white shadow rounded">
                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">
                  1
                </div>
                <img
                  className="mx-auto my-8"
                  src={"/assets/images/design_components.svg"}
                  alt="design mockup"
                />
                <h3 className="mb-2 font-bold font-heading">Design</h3>
                <p className="text-sm text-blueGray-400 leading-relaxed">
                  I create a design mockup to give you a visual interactive experience of your website before I build it.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
              <div className="p-12 bg-white shadow rounded">
                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">
                  2
                </div>
                <img
                  className="mx-auto my-8"
                  src={"/assets/images/developer_activity.svg"}
                  alt="develop code"
                />
                <h3 className="mb-2 font-bold font-heading">Develop</h3>
                <p className="text-sm text-blueGray-400 leading-relaxed">
                  I use technologies that suits your use case and build it on the promises we agreed upon.
                </p>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-3 mb-6">
              <div className="p-12 bg-white shadow rounded">
                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">
                  3
                </div>
                <img
                  className="mx-auto my-8"
                  src={"/assets/images/server_push.svg"}
                  alt="deploy on server"
                />
                <h3 className="mb-2 font-bold font-heading">Deploy</h3>
                <p className="text-sm text-blueGray-400 leading-relaxed">
                  I help you deploy your newly created website on the hosting provider that best suits your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
