import React from "react";
import { Link } from "gatsby"

function LandingHeader() {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="pt-20 pb-24 px-8 md:px-16 bg-blue-600 rounded">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full lg:w-1/2 px-8 flex">
              <div className="my-auto">
                <h2 className="text-3xl lg:text-4xl font-semibold text-white">
                  DesignTypist
                </h2>
                <h4 className="text-2xl lg:text-3xl mt-2 mb-6 text-blueGray-50 leading-loose italic">
                  "Happily building for a healthier future."
                </h4>
                <Link className="inline-block py-4 px-8 text-xs border bg-white text-blue-600 font-semibold leading-none hover:bg-blue-600 hover:border-white hover:text-white rounded transition duration-300"
                  to="/contact">Contact Me</Link>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
              <div className="relative max-w-sm sm:max-w-md mx-auto">
                <div
                  className="absolute bg-blue-600"
                  style={{
                    top: "22.8%",
                    left: "3%",
                    width: "25.2%",
                    height: "72%"
                  }}
                >
                  <img
                    className="object-cover w-full h-full object-left"
                    src="https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                    alt="web design"
                  />
                </div>
                <div
                  className="absolute bg-blue-600"
                  style={{
                    top: "3.8%",
                    left: "37%",
                    width: "25.2%",
                    height: "72%"
                  }}
                >
                  <img
                    className="object-cover w-full h-full"
                    src="https://images.unsplash.com/photo-1557853197-aefb550b6fdc?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=675&q=80"
                    alt="code development"
                  />
                </div>
                <div
                  className="absolute bg-blue-600"
                  style={{
                    top: "12.8%",
                    left: "70%",
                    width: "25.2%",
                    height: "72%"
                  }}
                >
                  <img
                    className="object-cover w-full h-full object-left"
                    src="https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1191&q=80"
                    alt="network server"
                  />
                </div>
                <img
                  className="relative mx-auto"
                  src={"/metis-assets/elements/three-iphones.png"}
                  alt="iphone"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingHeader;