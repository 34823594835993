import * as React from "react"
import { Link } from "gatsby"
import { Slide } from 'react-slideshow-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faCircle } 
  from '@fortawesome/free-solid-svg-icons'

const Projects =  ({ data })  => {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    canSwipe: false,
    indicators: true,
    prevArrow:  <button className="lg:p-10 p-5">
                  <FontAwesomeIcon className="text-blue-600"
                    icon={faCaretLeft} size="3x" />
                </button>,
    nextArrow:  <button className="lg:p-10 p-5">
                  <FontAwesomeIcon className="text-blue-600"  
                    icon={faCaretRight} size="3x" />
                </button>
  };
  
  const projects = data.allMarkdownRemark.nodes

  return (
    <section className="py-12 md:py-20">
      <Slide {...properties}>
          {projects.map((project, index) => {
            
            const title = project.frontmatter.title
            const description = project.frontmatter.description
            const image = project.frontmatter.image
            const date = project.frontmatter.date
            const slug = project.frontmatter.slug
            const link = project.frontmatter.link
            //const tags = project.frontmatter.tags
            console.log(project)

            return (
              <div key={index} className="container px-4 mx-auto each-slide">
                <div className="flex flex-wrap -mx-3">
                  <div className="relative w-full lg:w-1/3 mb-8 lg:mb-0 text-center lg:text-left">
                    <div className="max-w-md lg:max-w-xs lg:pr-16 mx-auto lg:ml-0 mb-6 lg:mb-0">
                      <h2 className="text-3xl md:text-4xl mb-4 font-bold font-heading">
                        My Work
                      </h2>
                      <hr className="mb-4" />
                      <ul className="my-2 hidden">
                        <li className="inline-block mr-2">
                          <span className="py-1 px-3 text-xs font-semibold bg-blue-100 text-blue-600 rounded-xl">Tags</span>  
                        </li>
                      </ul>
                      <h5 className="text-xs uppercase font-light text-gray-300">
                        {date}
                      </h5>
                      <h3 className="text-2xl md:text-3xl mb-4 font-bold font-heading">
                        {title}
                      </h3>
                      <p className="text-xs md:text-base mb-4 text-blueGray-400 leading-loose">
                        {description}
                      </p>
                      <Link className="hidden inline-block text-xs py-4 px-8 text-white font-semibold leading-none bg-blue-600 hover:bg-blue-700 rounded"
                        to={"project" + slug}>
                        Learn More
                      </Link>
                      <a className="inline-block text-xs py-4 px-8 text-white font-semibold leading-none bg-blue-600 hover:bg-blue-700 rounded"
                        href={link} target="_blank">
                        Visit Site
                      </a>
                    </div>
                  </div>
                  <div className="w-full lg:w-2/3 flex flex-wrap">
                    <div className="w-full px-3 mb-6 lg:mb-0">
                      <img className="fill-current text-green-600" src={"/assets/mockups/" + image} alt={title} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </Slide>
    </section>
  );
}

export default Projects
