import React from "react";
import { Link } from "gatsby"

function CalltoAction() {
  return (
    <section className="pt-20 pb-24 bg-blue-600">
      <div className="max-w-xl mx-auto text-center">
        <span className="inline-block py-1 px-3 bg-blue-500 text-xs text-white font-semibold rounded-xl">

        </span>
        <h2 className="mt-3 text-3xl md:text-4xl text-white font-bold font-heading">
          Let's keep in touch
        </h2>
        <div className="mt-6">
          <Link 
            className="inline-block text-xs py-4 px-8 bg-white hover:bg-blue-600 text-blue-600 hover:text-white font-semibold leading-none border hover:border-white rounded transition duration-300"
            to="/contact"
          >
            Contact Me
          </Link>
        </div>
      </div>
    </section>
  );
}

export default CalltoAction;
